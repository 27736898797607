import { Properties } from "../model/model"

export default class PropertyUtil {

  private static SYSTEM_PROPERTY_KEY_PREFIX = "$"

  static filteredProperties(properties: Properties): Properties {
    return Object.keys(properties)
      .filter((it) => this.validProperty(it, properties[it]))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: this.filteredPropertiesValue(key, properties[key])
        }),
        {}
      )
  }

  static filteredPropertiesValue(key: string, value: any): any {

    const isSystemProperty = key.startsWith(PropertyUtil.SYSTEM_PROPERTY_KEY_PREFIX)
    if (isSystemProperty) {
      return value
    }

    if (typeof value === "string") {
      if (value.length > 1024) {
        return value.slice(0, 1024)
      }
    }
    return value
  }

  private static validProperty(key: string, value: any): boolean {
    if (!key) {
      return false
    }

    if (typeof key !== "string") {
      return false
    }

    if (key.length > 128) {
      return false
    }

    if (typeof value === "string") {
      return true
    }

    if (typeof value === "boolean") {
      return true
    }

    if (typeof value === "number") {
      return !isNaN(value) && isFinite(value)
    }

    return false
  }
}
