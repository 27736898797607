import HacklePropertyGenerator from "../property/HacklePropertyGenerator"
import { v4 as uuid4 } from "uuid"
import { COOKIE_EXPIRE_DAYS, COOKIE_HID_KEY, COOKIE_USER_ID_KEY } from "../../config"
import { HackleUser, IdentifiersBuilder, IdentifierType, Properties, User } from "../../core/internal/model/model"

export class HackleUserResolver {
  constructor() {
    IdentifierManager.initialize()
  }

  resolveOrNull(input?: User | string): HackleUser | undefined {
    const user = this._resolveUser(input)
    return this._resolveHackleUserOrNull(user)
  }

  resolveAnonymousUser(): HackleUser {
    return {
      identifiers: {},
      properties: {},
      hackleProperties: this._getHackleProperties()
    }
  }

  _resolveUser(input?: User | string): User {
    if (!input) {
      return { id: getUserId() }
    }

    if (typeof input === "string") {
      return { id: input }
    }

    return input
  }

  _resolveHackleUserOrNull(user: User): HackleUser | undefined {
    const builder = new IdentifiersBuilder()
    builder.addIdentifiers(user.identifiers || {})
    if (user.id) {
      builder.add(IdentifierType.ID, user.id)
    }
    if (user.userId) {
      builder.add(IdentifierType.USER, user.userId)
    }
    builder.add(IdentifierType.DEVICE, user.deviceId || getUserId())
    const identifiers = builder.build()

    if (Object.keys(identifiers).length === 0) {
      return undefined
    }

    return {
      identifiers: identifiers,
      properties: user.properties || {},
      hackleProperties: this._getHackleProperties()
    }
  }

  _getHackleProperties(): Properties {
    let hackleProperties
    // @ts-ignore
    if (typeof window !== "undefined") {
      // @ts-ignore
      hackleProperties = HacklePropertyGenerator.generate(window)
    }
    return hackleProperties || {}
  }
}

export class IdentifierManager {
  static initialize() {
    const id = Cookie.get(COOKIE_HID_KEY)
    if (!id) {
      generateIdentifier()
    }
  }

  static reInitialize() {
    generateIdentifier()
  }
}

export function getUserId(): string {
  const userId = Cookie.get(COOKIE_USER_ID_KEY)
  if (userId) {
    return userId
  }
  const id = Cookie.get(COOKIE_HID_KEY)
  if (id) {
    return id
  } else {
    return generateIdentifier()
  }
}

export function setUserId(userId: string): void {
  Cookie.set(COOKIE_USER_ID_KEY, userId, COOKIE_EXPIRE_DAYS, true)
}

export function removeUserId(): void {
  Cookie.remove(COOKIE_USER_ID_KEY, true)
}

export function generateIdentifier(): string {
  const id = uuid4()
  Cookie.set(COOKIE_HID_KEY, id, COOKIE_EXPIRE_DAYS, true)
  return id
}

export class Cookie {
  static get(name: string): string | null {
    const nameEQ = name + "="

    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(nameEQ) === 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length))
      }
    }
    return null
  }

  static set(name: string, value: string, days?: number, is_cross_subdomain?: boolean, is_secure?: boolean, is_cross_site?: boolean) {
    let cdomain = "", expires = "", secure = ""

    if (is_cross_subdomain) {
      const domain = Cookie.extractDomain(document.location.hostname)
      cdomain = domain ? "; domain=." + domain : ""
    }

    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = "; expires=" + date.toUTCString()
    }

    if (is_cross_site) {
      is_secure = true
      secure = "; SameSite=None"
    }
    if (is_secure) {
      secure += "; secure"
    }

    const new_cookie_val = name + "=" + encodeURIComponent(value) + expires + "; path=/" + cdomain + secure

    document.cookie = new_cookie_val
    return new_cookie_val
  }

  static remove(name: string, is_cross_subdomain?: boolean) {
    Cookie.set(name, "", -1, is_cross_subdomain, false, false)
  }

  private static extractDomain(hostname: string): string {
    const DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]+\.[a-z.]{2,6}$/i
    const SIMPLE_DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]*\.[a-z]+$/i

    let domain_regex = DOMAIN_MATCH_REGEX

    const parts = hostname.split(".")
    const tld = parts[parts.length - 1]
    if (tld.length > 4 || tld === "com" || tld === "org") {
      domain_regex = SIMPLE_DOMAIN_MATCH_REGEX
    }

    const matches = hostname.match(domain_regex)
    return matches ? matches[0] : ""
  }
}
