import {
  MatchValueType,
  RemoteConfigDecision,
  DecisionReason,
  HackleRemoteConfig,
  HackleUser
} from "../../core/internal/model/model"
import HackleInternalClient from "../../core/internal/client/HackleInternalClient"
import Logger from "../../core/internal/logger"
import ObjectUtil from "../../core/internal/util/ObjectUtil"

const log = Logger.log

export default class HackleRemoteConfigImpl implements HackleRemoteConfig {
  private hackleInternalClient: HackleInternalClient
  private readonly hackleUser?: HackleUser

  constructor(hackleInternalClient: HackleInternalClient, hackleUser?: HackleUser) {
    this.hackleInternalClient = hackleInternalClient
    this.hackleUser = hackleUser
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    switch (typeof defaultValue) {
      case "string":
        return this._get(key, "STRING", defaultValue).value
      case "number":
        return this._get(key, "NUMBER", defaultValue).value
      case "boolean":
        return this._get(key, "BOOLEAN", defaultValue).value
      default:
        return defaultValue
    }
  }

  private _get(
    key: string,
    requiredType: MatchValueType,
    defaultValue: string | number | boolean
  ): RemoteConfigDecision {
    try {
      if (ObjectUtil.isNullOrUndefined(this.hackleUser)) {
        return RemoteConfigDecision.of(defaultValue, DecisionReason.INVALID_INPUT)
      }

      if (ObjectUtil.isNullOrUndefined(key)) {
        log.error("parameterKey must not be empty")
        return RemoteConfigDecision.of(defaultValue, DecisionReason.INVALID_INPUT)
      }

      return this.hackleInternalClient._remoteConfig(key, this.hackleUser, requiredType, defaultValue)
    } catch (e) {
      log.error(`Unexpected exception while deciding remote config parameter[${key}]. Returning default value. : ${e}`)
      return RemoteConfigDecision.of(defaultValue, DecisionReason.EXCEPTION)
    }
  }
}
